import './UrgentPurchaseable.css';
import Modal from "../../component/modal/Modal";
import { Page } from "../../hooks/usePage";
import Purchaseable from "./Purchaseable";
import { useEffect, useState } from 'react';
import { purchase } from '../../api/API';
import { timespanToHumanReadable } from '../../util/TimeUtil';

type UrgentPurchaseableProps = {
    page: Page;
    purchaseable: any;
    onPurchase: () => void;
};

const UrgentPurchaseable = ({ page, purchaseable, onPurchase }: UrgentPurchaseableProps) => {
    const [expiryString, setExpiryString] = useState<string | undefined>(undefined);
    const updateExpiryString = () => {
        setExpiryString(timespanToHumanReadable(purchaseable.expiry - Date.now()));
    }

    useEffect(() => {
        let interval: any = null;

        updateExpiryString();
        interval = setInterval(updateExpiryString, 1000);

        return () => clearInterval(interval);
    }, [purchaseable.expiry]); // eslint-disable-line react-hooks/exhaustive-deps

    const doPurchase = () => {
        purchase(page.pageInfo.url, purchaseable.id, 1).then((data) => {
            if (data === 401) {
                alert("You are not authorized to perform this action.");
                return;
            }

            if (!data.success) {
                alert("An error occurred. Please try again.");
                return;
            }

            page.reloadPageInfo();
            onPurchase();
        });
    };

    return (
        <div id="urgent-purchaseable">
            <Modal isOpen>
                <div className="holder">
                    <p className="lastChance">{expiryString}</p>
                    <p className="lastChance">LAST CHANCE!!!!</p>
                    <Purchaseable purchaseable={purchaseable} total={0} balance={99999} onIncrement={doPurchase} />
                </div>
            </Modal>
        </div>
    )
};

export default UrgentPurchaseable;