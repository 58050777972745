import "./Plug.css";
import { useEffect, useState } from "react";
import Modal from "../../component/modal/Modal";
import RadioButtons from "../../component/radio/RadioButtons";
import { timespanToHumanReadable } from "../../util/TimeUtil";
import ButtplugController from "./controls/ButtplugController";

const Plug = () => {
    const [times] = useState<any>({
        10: 0,
        20: 0,
        30: 0,
        40: 0,
        50: 0,
        60: 0,
        70: 0,
        80: 0,
        90: 0,
        100: 0,
    });

    const [allowedTime, setAllowedTime] = useState<number>(0);
    const [selectedPower, setSelectedPower] = useState<number>(10);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [switchDisabled, setSwitchDisabled] = useState<boolean>(false);

    useEffect(() => {
        setAllowedTime(times[selectedPower]);
    }, [selectedPower]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="plug">
            <h3>Plug</h3>
            <button onClick={() => setIsOpen(true)}>Open plug controls</button>

            <Modal isOpen={isOpen}>
                <div className="header">
                    <h3>Plug Controls</h3>
                    <button className="small secondary" onClick={() => setIsOpen(false)} disabled={switchDisabled}>Close</button>
                </div>
                <div className="plug-content">
                    <div>
                        <p><b>Power:</b></p>
                        <RadioButtons disabled={switchDisabled} values={Object.keys(times)} name="plug-time" selected={selectedPower.toString()} onChange={val => setSelectedPower(Number.parseInt(val))} />
                    </div>
                    <p className="label"><b>Time available: </b>{timespanToHumanReadable(allowedTime)}</p>
                    <ButtplugController allowedTime={allowedTime} setAllowedTime={(num) => setAllowedTime(num)} strength={selectedPower / 100} onStart={() => { setSwitchDisabled(true); times[10] = 0 }} onStop={() => setSwitchDisabled(false)} />
                </div>
            </Modal>
        </div>
    );
};

export default Plug;