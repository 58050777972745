import './Create.css';
import { useState } from "react";
import { createPage } from "../api/API";

const Create = () => {
    const [error, setError] = useState<string | null>(null);

    const [title, setTitle] = useState("");
    const [dailyPoints, setDailyPoints] = useState("0.0");
    const [domName, setDomName] = useState("");
    const [domPassword, setDomPassword] = useState("");
    const [subName, setSubName] = useState("");
    const [subPassword, setSubPassword] = useState("");

    const submit = (e: React.FormEvent) => {
        e.preventDefault();

        const parsedDailyPoints = parseFloat(dailyPoints);

        if (parsedDailyPoints < 0 || isNaN(parsedDailyPoints)) {
            setError("Daily points must be a positive number");
            return;
        }

        createPage(title.trim(), parsedDailyPoints, domName, domPassword, subName, subPassword).then((data) => {
            if (!data || !isNaN(+data)) {
                setError("Failed to create page");
                return;
            }
            
            window.location.href = "/" + data.url;
        }).catch((err) => {
            console.error(err);
            setError("Failed to create page");
        });
    };

    return (
        <div id="create">
            <h2>Create</h2>
            <form onSubmit={submit}>
                <label htmlFor="title">Title:</label>
                <input name="title" type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} required={true} minLength={8} />
                <label htmlFor="dailyPoints">Daily Points  (decimal x.x):</label>
                <input name="dailyPoints" type="number" placeholder="Daily Points (decimal x.x)" value={dailyPoints} onChange={(e) => setDailyPoints(e.target.value)} required={true} min={0} defaultValue={0} step={0.5} />
                <label htmlFor="domName">Dom Name:</label>
                <input name="domName" type="text" placeholder="Dom Name" value={domName} onChange={(e) => setDomName(e.target.value)} required={true} minLength={4} />
                <label htmlFor="domPassword">Dom Password:</label>
                <input name="domPassword" type="password" placeholder="Dom Password" value={domPassword} onChange={(e) => setDomPassword(e.target.value)} required={true} minLength={8} />
                <label htmlFor="subName">Sub Name:</label>
                <input name="subName" type="text" placeholder="Sub Name" value={subName} onChange={(e) => setSubName(e.target.value)} required={true} minLength={4} />
                <label htmlFor="subPassword">Sub Password:</label>
                <input name="subPassword" type="password" placeholder="Sub Password" value={subPassword} onChange={(e) => setSubPassword(e.target.value)} required={true} minLength={8} />
                <button type="submit">Create</button>
            </form>
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default Create;