import './ButtplugController.css';
import { ButtplugBrowserWebsocketClientConnector, ButtplugClient } from "buttplug";
import { useEffect, useState } from "react";

enum State {
    Waiting,
    Disconnected,
    Connecting,
    Connected,
    Exception
}

type ButtplugControllerProps = {
    allowedTime: number;
    setAllowedTime: (time: number) => void;
    strength: number;
    onStart?: () => void;
    onConnect?: () => void;
    onStop?: () => void;
};

const ButtplugController = ({ allowedTime, setAllowedTime, strength, onStart = () => { }, onConnect = () => { }, onStop = () => { } }: ButtplugControllerProps) => {
    const [state, setState] = useState<State>(State.Waiting);
    const [client, setClient] = useState<ButtplugClient | null>(null);
    const [device, setDevice] = useState<any | null>(null);

    const [isRunning, setIsRunning] = useState(false);
    const [urlEntry, setUrlEntry] = useState("ws://localhost:12345");

    useEffect(() => {
        if (!isRunning) return;

        const timeout = setTimeout(() => {
            const newAllowedTime = allowedTime - 100;
            setAllowedTime(newAllowedTime);

            if (newAllowedTime <= 0) {
                end();
                return;
            }
        }, 100);

        return () => clearTimeout(timeout);
    }, [isRunning, allowedTime]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            if (state !== State.Connected) return;

            try {
                device?.vibrate(0);
                client?.disconnect();
            } catch (e) {
                console.error(e);
            }

            setClient(null);
            setDevice(null);
            setState(State.Waiting);
            onStop();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const setup = () => {
        if (state !== State.Waiting) return;

        const newClient = new ButtplugClient("Loyalty Programme <3");
        newClient.addListener("deviceadded", async (device) => setDevice(device));
        newClient.addListener("deviceremoved", async () => setDevice(null));

        setClient(newClient);
        setState(State.Disconnected);

        onStart();
    };

    const connect = async () => {
        if (state !== State.Disconnected) return;
        if (!client) return;
        setState(State.Connecting);

        try {
            const conn = new ButtplugBrowserWebsocketClientConnector(urlEntry);
            await client.connect(conn);

            setState(State.Connected);
            onConnect();

            await client.startScanning();
        } catch (e) {
            console.error(e);
            alert("Failed to connect to Intiface");
            setState(State.Disconnected);
            return;
        }
    };

    const cancelConnect = () => {
        if (state !== State.Disconnected) return;

        setClient(null);
        setState(State.Waiting);
        onStop();
    }

    const vibrate = async (intensity: number) => {
        if (state !== State.Connected) return;
        if (!device) return;

        setIsRunning(intensity > 0);

        try {
            await device.vibrate(intensity);
        } catch (e) {
            console.error(e);
            setState(State.Exception);
            setIsRunning(false);
        }
    };

    const end = async () => {
        if (state !== State.Connected) return;

        try {
            await client!.disconnect();
        } catch (e) {
            console.error(e);
        }

        setClient(null);
        setState(State.Waiting);
        setAllowedTime(0);
        onStop();
    };

    const reconnect = async () => {
        if (state !== State.Exception) return;

        try {
            setDevice(null);
            await client!.connect(new ButtplugBrowserWebsocketClientConnector(urlEntry));
            setState(State.Connected);
            onConnect();
        } catch (e) {
            console.error(e);
            alert("Failed to reconnect to Intiface");
            setState(State.Exception);
            return;
        }
    }

    return (
        <div id="buttplug-controller">
            {allowedTime <= 0 && <p className="secondary">No time... Buy some :3</p>}
            {allowedTime > 0 && (
                <>
                    {state === State.Waiting && <button onClick={setup}>Start</button>}
                    {state === State.Disconnected &&
                        <div className="input">
                            <input type="text" placeholder="Buttplug IP" value={urlEntry} onChange={e => setUrlEntry(e.target.value)} />
                            <button onClick={connect}>Connect</button>
                            <button onClick={cancelConnect} className="secondary">Cancel</button>
                        </div>
                    }
                    {state === State.Connecting && <p>Connecting...</p>}
                    {state === State.Connected && (<>
                        {!device && <p className='secondary'>Please wait for a device...</p>}
                        {device && (<div className="controls">
                            <button onClick={() => vibrate(strength)}>Vibrate</button>
                            <button onClick={end} className="destructive">End (lose time)</button>
                        </div>)}
                    </>)}
                    {state === State.Exception && <div><p className="negative">Something went wrong and you got disconnected!</p><button onClick={reconnect}>Reconnect</button></div>}
                </>
            )}
        </div>
    )
};

export default ButtplugController;