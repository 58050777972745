import './PurchaseableList.css';
import { useEffect, useState } from "react";
import Purchaseable from "./Purchaseable";
import { deletePurchaseable, newPurchaseable, purchase, savePurchaseable } from '../../api/API';
import EditPurchaseableDialog from './EditPurchaseable';
import { Page } from '../../hooks/usePage';
import UrgentPurchaseable from './UrgentPurchaseable';
import PointShop from '../points/shop/PointShop';

type PurchaseableListProps = {
    page: Page;
};

const PurchaseableList = ({ page }: PurchaseableListProps) => {
    const { purchaseables, isOwner: admin, pageInfo, reloadPurchaseables } = page;

    const [show, setShow] = useState<boolean>(false);
    const [purchasing, setPurchasing] = useState<boolean>(false);
    const [selections, setSelections] = useState<{ [key: number]: number }>({});
    const [sortedPurchaseables, setSortedPurchaseables] = useState<any[]>([]);
    const [editing, setEditing] = useState<any | null>(null);

    const updateSortedPurchaseables = () => {
        const sorted = [...purchaseables].filter(it => admin || !it.expiry || Date.now() < it.expiry);

        sorted.sort((a, b) => {
            if (a.expiry && !b.expiry) return -1;
            if (!a.expiry && b.expiry) return 1;
            if (a.expiry && b.expiry) return a.expiry - b.expiry;
            return b.price - a.price;
        });

        setSortedPurchaseables(sorted);
    }

    useEffect(() => {
        setSelections({});
        updateSortedPurchaseables();
    }, [purchaseables]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!show) return;

        const interval = setInterval(() => {
            if (admin) return;

            const expired = [];
            for (const purchaseable of sortedPurchaseables) {
                if (purchaseable.expiry && Date.now() > purchaseable.expiry) {
                    expired.push(purchaseable.id);
                }
            }

            if (expired.length === 0) return;

            const newSelections = { ...selections };
            expired.forEach(id => delete newSelections[id]);

            setSelections(newSelections);
            updateSortedPurchaseables();
        }, 1000);

        return () => clearInterval(interval);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getTotal = (): number => {
        let total = 0;
        for (const id in selections) {
            total += selections[id] * sortedPurchaseables.find((purchaseable) => purchaseable.id == id).price; // eslint-disable-line eqeqeq
        }
        return total;
    }

    const onSelect = (id: number, increment: boolean) => {
        const newSelections = { ...selections };

        if (increment) {
            newSelections[id] = newSelections[id] ? newSelections[id] + 1 : 1;
        } else {
            newSelections[id] = newSelections[id] ? newSelections[id] - 1 : 0;
        }

        setSelections(newSelections);
    }

    const close = () => {
        if (purchasing) return;

        setSelections({});
        setShow(false);
    }

    const doPurchase = async () => {
        if (purchasing) return;
        setPurchasing(true);

        let anyFailed = false;

        for (const id in selections) {
            const purchaseable = sortedPurchaseables.find((purchaseable) => purchaseable.id == id); // eslint-disable-line eqeqeq
            const amount = selections[id];

            const data = await purchase(page.pageInfo.url, purchaseable.id, amount);

            if (!data || !isNaN(+data)) {
                anyFailed = true;
                continue;
            }

            await new Promise((resolve) => setTimeout(resolve, 100));
        }

        if (anyFailed) {
            alert("Some items failed to purchase. Check logs & try again later.");
        }

        setPurchasing(false);
        reloadPurchaseables();
    };

    const [showUrgent, setShowUrgent] = useState<boolean>(false);
    const [override, setOverride] = useState<boolean>(false);

    useEffect(() => {
        const interval = setInterval(() => {
            checkShowUrgent();
        }, 1000);

        return () => clearInterval(interval);
    });

    const checkShowUrgent = () => {
        if (admin || purchaseables.length === 0) return setShowUrgent(false);

        if (sortedPurchaseables.length === 0) {
            setOverride(false);
            setShowUrgent(false);
        }

        const doShow = sortedPurchaseables[0] && sortedPurchaseables[0].expiry && sortedPurchaseables[0].expiry - Date.now() < 5000 && sortedPurchaseables[0].expiry - Date.now() > 0;
        if (doShow) {
            if (!override) {
                setShowUrgent(true);
            }
        } else {
            setShowUrgent(false);
            setOverride(false);
        }
    }

    const total = getTotal();
    return (
        <>
            <div id="purchaseables" onClick={e => purchasing && e.stopPropagation()}>
                <div className="header">
                    <h3>Shop</h3>
                    <button onClick={() => !show ? setShow(true) : close()}>{show ? "Hide" : "Show"} Shop</button>
                    <PointShop page={page} />

                    {!admin && show && <div className="purchase">
                        <span className="total">Selected cost: {total > 0 ? <span className="negative">-{total}</span> : <span>0</span>}<span className="neutral"> / {pageInfo.balance} points</span></span>
                        <button onClick={doPurchase} disabled={total === 0 || purchasing}>Purchase</button>
                        <button className="secondary" disabled={total === 0 || purchasing} onClick={() => setSelections({})}>Clear</button>
                    </div>}
                </div>
                {show && <div className={"list"}>
                    {sortedPurchaseables.map((purchaseable) => (
                        <Purchaseable
                            disabled={purchasing}
                            purchaseable={purchaseable}
                            total={total}
                            balance={pageInfo.balance}
                            selections={selections}
                            admin={admin}
                            onIncrement={() => onSelect(purchaseable.id, true)}
                            onDecrement={() => onSelect(purchaseable.id, false)}
                            onEdit={() => setEditing(purchaseable)}
                        />
                    ))}

                    {admin && <div className="purchaseable add" onClick={() => setEditing({})}>
                        <div className="icon">+</div>
                    </div>}
                </div>
                }
                {editing && <EditPurchaseableDialog purchaseable={editing} save={async (purchaseable: any): Promise<boolean> => {
                    const res = await (async () => {
                        if (purchaseable.id === undefined) {
                            return await newPurchaseable(pageInfo.url, purchaseable);
                        } else {
                            return await savePurchaseable(pageInfo.url, purchaseable);
                        }
                    })();

                    if (!res || !isNaN(+res)) {
                        alert("Failed to save. Check logs & try again later.");
                        return false;
                    }

                    setEditing(null);
                    reloadPurchaseables();
                    return true;
                }}
                    del={async (purchaseable: any): Promise<boolean> => {
                        const res = await deletePurchaseable(pageInfo.url, purchaseable.id);

                        if (!res || !isNaN(+res)) {
                            alert("Failed to delete. Check logs & try again later.");
                            return false;
                        }

                        setEditing(null);
                        reloadPurchaseables();
                        return true;
                    }}
                    close={() => setEditing(null)} />}
            </div>

            {showUrgent && <UrgentPurchaseable page={page} purchaseable={purchaseables[0]} onPurchase={() => { setShowUrgent(false); setOverride(true) }} />}
        </>
    )
};

export default PurchaseableList;