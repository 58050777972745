import { useState } from "react";
import "./ManagePoints.css";

import { adjustPoints } from "../../api/API";
import { Page } from "../../hooks/usePage";

type ManagePointsProps = {
    page: Page;
};

const ManagePoints = ({ page }: ManagePointsProps ) => {
    const [pointsError, setPointsError] = useState<string | null>(null);
    const [pointsFieldValue, setPointsFieldValue] = useState("");
    const [messageFieldValue, setMessageFieldValue] = useState("");
    
    const validatePoints = (add: boolean) => {
        setPointsError(null);

        if (!pointsFieldValue) {
            setPointsError("Please enter a point amount.");
            return false;
        }

        const asFloat = parseFloat(pointsFieldValue);
        const asString = asFloat.toFixed(1);

        if (isNaN(asFloat)) {
            setPointsError("Please enter a valid number.");
            return false;
        }

        if (asFloat <= 0) {
            setPointsError("Please enter a positive number.");
            return false;
        }

        if (!window.confirm(`Are you sure you want to ${add ? "ADD" : "TAKE"} ${asString} points?`)) return;

        adjustPoints(page.pageInfo.url, add ? asFloat : -asFloat, messageFieldValue).then((data) => {
            if (data === 401) {
                setPointsError("You are not authorized to perform this action.");
                return;
            }
            
            if (!data.success) {
                setPointsError("An error occurred. Please try again.");
                return;
            }

            page.reloadPageInfo();
        }).catch((err) => {
            console.error(err);
            setPointsError("An error occurred. Please try again.");
        })

        return true;
    };

    return (
        <div id="managepoints">
            <p><b>Manage</b></p>
            {pointsError && <p className="error">{pointsError}</p>}
            <label htmlFor="points">Point amount*:</label>
            <input name="points" type="number" placeholder="Point amount*" value={pointsFieldValue} onChange={(e) => setPointsFieldValue(e.target.value)} />
            <label htmlFor="text">Message (optional):</label>
            <input name="text" type="text" placeholder="Message (optional)" value={messageFieldValue} onChange={(e) => setMessageFieldValue(e.target.value)} />
            <div>
                {page.isOwner && <button onClick={_ => validatePoints(true)}>Add +</button>}
                <button onClick={_ => validatePoints(false)}>Take -</button>
            </div>
        </div>
    );
};

export default ManagePoints;