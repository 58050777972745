import './PointShop.css';
import { useState } from 'react';
import Modal from '../../../component/modal/Modal';
import ModalHeader from '../../../component/modal/header/ModalHeader';
import { Page } from '../../../hooks/usePage';
import { Bundle, pointBundles } from '../../../PointBundles';
import PointShopTile from './tile/PointShopTile';
import ShoppingCart from './cart/ShoppingCart';

type PointShopProps = {
    page: Page;
};

const PointShop = ({ page }: PointShopProps): JSX.Element => {
    const [cart, setCart] = useState<Bundle[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    const addToCart = (bundle: Bundle) => {
        setCart([...cart, bundle]);
    }

    const buy = () => {
        console.log('buy');
    };

    return (
        <>
            <button onClick={() => setOpen(!open)}>Top up points</button>
            <Modal id="point-shop" isOpen={open}>
                <ModalHeader title="Point Shop" onClose={() => setOpen(false)} />
                <div className="content">
                    <div className="bundles">
                        {pointBundles.map(bundle => <PointShopTile page={page} bundle={bundle} onSelect={(bundle: Bundle) => addToCart(bundle)} highestPricePer={pointBundles[0].price / pointBundles[0].points} />)}
                    </div>
                    <ShoppingCart page={page} cart={cart} clear={() => setCart([])} remove={(bundle: Bundle) => {
                        const index = cart.indexOf(bundle);
                        if (index > -1) {
                            cart.splice(index, 1);
                            setCart([...cart]);
                        }
                    }} 
                    checkout={buy}/>
                </div>
            </Modal>
        </>
    );
};

export default PointShop;