import { LogoutButton } from '../component/logout/LogoutButton';
import { Page } from '../hooks/usePage';
import './PageBoth.css';
import Logs from "./logs/Logs";
import Plug from './plug/Plug';
import Points from "./points/Points";
import PurchaseableList from './purchaseables/PurchaseableList';

type PageSubProps = {
    page: Page;
};

const PageSub = ({ page } : PageSubProps) => {
    const { pageInfo } = page;

    return (
        <div id="pagecontent">
            <div className="header">
                <h2>{pageInfo.title}</h2>
                <span>Logged in as {pageInfo.user_name} (sub)</span>
                <LogoutButton pageUrl={pageInfo.url} />
                <p>Dom: {pageInfo.owner_name}</p>
            </div>
            <Points page={page} />
            <PurchaseableList page={page} />
            <Plug />
            <Logs page={page} />
        </div>
    );
}

export default PageSub;