import { removeSession } from "../../api/API";
import "./LogoutButton.css";

export type LogoutButtonProps = {
    pageUrl: string;
}

export const LogoutButton = ({ pageUrl }: LogoutButtonProps) => {
    return (
        <button id="logout" className="secondary small" onClick={() => {
            removeSession(pageUrl);
            window.location.reload();
        }}>Log out</button>
    );
};