import './Modal.css';

type ModalProps = {
    children: React.ReactNode;
    isOpen: boolean;
    id?: string;
};

const Modal = ({ children, isOpen, id }: ModalProps) => {
    if (!isOpen) return null;
    return (
        <div className="modal" role="dialog" id={id}>
            <div className="modal-holder">
                {children}
            </div>
        </div>
    )
};

export default Modal;
