import { useEffect, useState } from 'react';
import './Home.css';
import { getPageInfo } from '../api/API';

const Home = () => {
    const [knownPages, setKnownPages] = useState<any[]>([]);

    useEffect(() => {
        const pageIds = [];

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key && key.startsWith("session-")) {
                const pageUrl = key.substring(8);
                pageIds.push(pageUrl);
            }
        }

        const newKnownPages = pageIds.map(async (pageUrl) => {
            const page = await getPageInfo(pageUrl);
            if (!isNaN(+page)) return;
            return page.page;
        });

        Promise.all(newKnownPages).then((pages) => {
            setKnownPages(pages);
        });
    }, []);

    return (
        <div id="home">
            <h1>:3</h1>
            <ul>
                {knownPages.map((page) => {
                    return (
                        <li key={page.id}>
                            <a href={`${window.location.href}${page.url}`}>{page.title}</a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Home;