import { useState } from "react";
import { authenticate } from "../api/API";

type PageLoginProps = {
    pageUrl: string;
    onLogin: () => void;
}

const PageLogin = ({ pageUrl, onLogin }: PageLoginProps ) => {
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);

    const auth = async () => {
        setError(null);
        
        authenticate(pageUrl, password).then((data) => {
            if (data?.session_id) {
                onLogin();
            } else {
                setError("Invalid password");
            }
        });
    }

    return (
        <div>
            <h1>Login</h1>
            <input type="password" placeholder="Password" value={password} onChange={(data) => setPassword(data.target.value)} />
            <button onClick={auth}>Login</button>
            {error && <p>{error}</p>}
        </div>
    );
}

export default PageLogin;