import './PointShopTile.css'
import { Bundle, calculateBonus } from "../../../../PointBundles";
import { Page } from '../../../../hooks/usePage';

type TileProps = {
    page: Page;
    bundle: Bundle;
    onSelect: (bundle: Bundle) => void;
    highestPricePer: number;
};

const PointShopTile = ({ page, bundle, onSelect, highestPricePer }: TileProps): JSX.Element => {
    const { points, price, tag, highlight = false } = bundle;
    const bonus = calculateBonus(bundle);
    const normalCost = bundle.price + ( bonus ?? 0 * highestPricePer);

    return (
        <div className={`point-shop-tile${highlight ? " highlight" : ""}`}>
            <h3>{points} points{bonus > 0 && <span className="small primary"> +{bonus} BONUS!</span>}</h3>
            <span className="secondary small">{tag ?? '\u00A0'}</span>
            <div className="buy">
                <span>{price}€ {bonus > 0 && <span className="old-price">{normalCost}€</span>}</span>
                {!page.isOwner && <button className="small" onClick={() => onSelect(bundle)}>Add to cart</button>}
            </div>
        </div>
    );
};

export default PointShopTile;