import './RadioButton.css';

type RadioProps = {
    name: string;
    value: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
};

const Radio = ({ name, value, checked, onChange, disabled }: RadioProps) => {
    return (
        <label className={`radio${checked ? " checked" : ""}${disabled ? " disabled" : ""}`}>
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
            {value}
        </label>
    );
};

export default Radio;