import { LogoutButton } from '../component/logout/LogoutButton';
import { Page } from '../hooks/usePage';
import './PageBoth.css';
import Logs from "./logs/Logs";
import Points from "./points/Points";
import PurchaseableList from './purchaseables/PurchaseableList';

type PageOwnerProps = {
    page: Page;
};

const PageOwner = ({ page } : PageOwnerProps) => {
    return (
        <div id="pagecontent">
            <div className="header">
                <h2>{page.pageInfo.title}</h2>
                <span>Logged in as {page.pageInfo.owner_name} (dom)</span>
                <LogoutButton pageUrl={page.pageInfo.url} />
                <p>Sub: {page.pageInfo.user_name}</p>
            </div>
            <Points page={page} />
            <PurchaseableList page={page} />
            <Logs page={page}  />
        </div>
    );
}

export default PageOwner;